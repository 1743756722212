<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between table-header">
            <h2 class="text-primary mb-1">
                Nama Paket: {{ namapaket }}
            </h2>
        </div>

        <div class="d-flex align-items-center justify-content-between table-header">
            <div class="d-flex align-items-center">
                <label class="inline-label d-none d-md-inline">Show</label>
                <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPage" />
            </div>

            <div class="d-flex">
                <b-button variant="danger" @click="resetSearch()" class="ml-1">
                    <feather-icon icon="RefreshCcwIcon" />
                </b-button>

                <search :add-button="true"
                    @addButtonAction="$router.push(`/paket/detail/menu/form/${$route.params.id}`)"
                    @handleAdvanceSearch="handleSearch" @resetAdvanceSearch="handleReset" />

                <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'paket' })">
                    <feather-icon icon="ListIcon" />
                    <span class="d-none d-md-inline">Back To Paket</span>
                </b-button>
            </div>
        </div>

        <b-table class="border mt-1" :fields="tableField" :items="tableData" show-empty responsive hover
            :busy="loading">
            <template #table-busy>
                <div class="text-center text-danger my-2" style="padding:50px 0">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading...</strong>
                </div>
            </template>

            <template #cell(action)="data">
                <b-button-group>
                    <b-button variant="flat-dark" @click.prevent="handleEdit(data.item.kodemenu)" v-b-tooltip.hover
                        title="Edit">
                        <feather-icon size="20" icon="EditIcon" />
                    </b-button>

                    <b-button variant="flat-dark" @click.prevent="handleRemove(data.item.kodemenu, data.item.namamenu)"
                        v-b-tooltip.hover title="Delete">
                        <feather-icon size="20" icon="TrashIcon" />
                    </b-button>

                    <b-button variant="flat-dark"
                        @click.prevent="handleDetail(data.item.kodepaket.kodepaket, data.item.kodemenu, data.item.punyaoptional)"
                        v-b-tooltip.hover title="Menu Optional">
                        <feather-icon size="20" icon="EyeIcon" />
                    </b-button>
                </b-button-group>
            </template>

            <template #cell(no)="data">
                {{ data.index + 1 }}
            </template>

            <template #cell(nama)="data">
                <span class="text-nowrap">{{ data.item.nama }}</span>
            </template>

            <template #cell(punyaoptional)="data">
                <span class="text-nowrap">{{ data.item.punyaoptional == 0 ? 'Tidak' : 'Ya' }}</span>
            </template>

            <template #cell(hargapaket)="data">
                <div class="text-right btn btn-sm btn-danger">
                    {{ convertToIDR(data.item.hargapaket) }}
                </div>
            </template>

            <template #empty="scope">
                <div class="text-center my-75">
                    <b-img :src="ClipboardsSecondary" />
                    <div class="text-secondary font-small-3 mt-75">
                        Tidak ada data
                    </div>
                </div>
            </template>
        </b-table>

        <div class="d-flex justify-content-between flex-wrap">
            <label class="inline-label">Count : {{ tableData.length }} {{ tableData.length > 1 ? 'Items' : 'Item'
                }}</label>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
        </div>
    </b-card>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { convertToIDR } from '@/@core/utils/utils'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import Search from './SearchMenu.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2';

export default {
    components: {
        BFormSelect,
        Search,
    },
    data() {
        return {
            ClipboardsSecondary,
            perPage: 10,
            currentPage: 1,
            rows: 0,
            searchVal: '',
            perPageOptions: [
                { value: 10, text: '10' },
                { value: 25, text: '25' },
                { value: 50, text: '50' },
                { value: 100, text: '100' },
            ],
            tableField: [
                { key: 'no', label: 'No', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
                { key: 'namamenu', label: 'Nama Menu', thClass: 'text-center' },
                { key: 'urutan', label: 'Urutan Menu', class: 'text-center', thClass: 'text-center' },
                { key: 'punyaoptional', label: 'Punya Optional', class: 'text-center', thClass: 'text-center' },
                { key: 'makanan.namamakanan', label: 'Nama Makanan', thClass: 'text-center' },
                { key: 'action', label: 'Action', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
            ],
            tableData: [],
            loading: false,
            namapaket: '',
            kodepaket: '',
            kodemenu: ''
        }
    },
    watch: {
        currentPage(x, y) {
            if (x !== y) {
                this.getListData()
            }
        },
    },

    mounted() {
        this.getListData()
        this.getDetailData()
    },

    methods: {
        convertToIDR,
        handleSearch(val) {
            this.searchVal = val
            this.getListData()
        },

        handleReset(val) {
            this.searchVal = val
            this.getListData();
        },

        handlePerPage(val) {
            this.perPage = val
            this.getListData()
        },

        resetSearch() {
            this.searchVal = ''
            this.getListData()
        },

        handleEdit(kodemenu) {
            this.$router.push(`/paket/detail/menu/edit/${kodemenu}`);
        },

        handleDetail(kodepaket, kodemenu, punyaoptional) {
            if (punyaoptional == 1) {
                this.$router.push(`/paket/detailmenu/form/${kodepaket}/${kodemenu}`);
            } else {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'info',
                        text: 'Menu yang dipilih tidak punya optional',
                    },
                });
            }
        },

        async handleRemove(kodemenu, namamenu) {
            const isConfirmed = await Swal.fire({
                title: 'Konfirmasi',
                text: `Apakah Anda yakin ingin menghapus ${namamenu}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal',
            });

            if (!isConfirmed.value) {
                return;
            }

            this.$http.post('/api/menu/delete', { kodemenu: kodemenu }).then(res => {
                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    });
                    return;
                }

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: '👋Berhasil menghapus data',
                    },
                });

                this.getListData()

            }).catch(e => {
                const errorMessage = e.response ? e.response.data.message : 'Gagal menghapus data';

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: errorMessage,
                    },
                });
            });
        },

        getListData() {
            this.loading = true;
            const params = {
                namamenu:  '',
                page: this.currentPage,
                perPage: this.perPage,
                keyword: this.searchVal.Keyword ?? '',
                punyaoptional: this.searchVal.punyaoptional == true ? 1 : this.searchVal.punyaoptional == false ? 0 : null,
            };

            this.$http.get(`/api/menu/listmenupaket/${this.$route.params.id}`, { params: params }).then(res => {
                this.loading = false;
                this.tableData = res.data.data;
                this.rows = res.data.maxPage * this.perPage;

                if (this.searchVal != '') {
                    if (res.data.data.length > 0) {
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: 'Pencarian data berhasil ditemukan',
                            },
                        });
                    } else {
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'BellIcon',
                                variant: 'warning',
                                text: 'Pencarian data tidak ditemukan',
                            },
                        });
                    }
                }
            }).catch(e => {
                this.loading = false;
                // this.$toast({
                //     component: ToastificationContent, position: 'top-right',
                //     props: {
                //         title: 'Notification',
                //         icon: 'SlashIcon',
                //         variant: 'danger',
                //         text: 'Gagal mendapatkan data' + e,
                //     },
                // });
                this.tableData = [];
                this.rows = 0;
            });
        },

        getDetailData() {
            this.$http.get(`/api/paket/detail/${this.$route.params.id}`).then(res => {
                const response = res.data.data
                this.kodemenu = response.kodemenu
                this.kodepaket = response.kodepaket
                this.namapaket = response.nama
                this.data = { idPaket: response.kodepaket }
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })
        },
    }
}
</script>